const contestDownloadColumn = {
    name: {
        label: '競賽報表名稱',
        type: 'string',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    uploadedAt: {
        label: '報表生產日期',
        type: 'string',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    status: {
        label: '報表狀態',
        type: 'string',
        position: 'center',
        width: 1,
        widthPx: 20
    },
    reportLink: {
        label: '下載報表',
        type: 'string',
        position: 'center',
        width: 1,
        widthPx: 20
    }
}

const performanceDownloadColumn = {
    name: {
        label: '業績報表名稱',
        type: 'string',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    createdAt: {
        label: '報表生產日期',
        type: 'string',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    status: {
        label: '報表狀態',
        type: 'string',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    reportLink: {
        label: '下載報表',
        type: 'string',
        position: 'center',
        width: 1,
        widthPx: 20
    }
}

const backstageReportMode = {
    BackstageReportContest: { label: '競賽報表下載', value: 'contest' },
    BackstageReportPerformance: { label: '業績報表下載', value: 'performance' }
}

export { contestDownloadColumn, performanceDownloadColumn, backstageReportMode }
